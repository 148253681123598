
const getMonogram = (name) => {
    const nameParts = name.trim().split(/\s+/, 3);
    let monogram = nameParts[0].charAt(0).toUpperCase();

    if (nameParts.length > 1) {
        monogram += nameParts[1].charAt(0).toUpperCase();
    }

    if (nameParts.length > 2) {
        monogram += nameParts[2].charAt(0).toUpperCase();
    }

    return monogram;
};

function Message(props) {
    const ownMessageClasses = "p-6 mb-6 ml-6 text-base bg-white rounded-lg border border-gray-200 shadow-sm lg:ml-12 dark:bg-gray-800 dark:border-gray-700";
    const otherMessageClasses = "p-6 mb-6 text-base bg-white rounded-lg border border-gray-200 shadow-sm dark:bg-gray-800 dark:border-gray-700"
    const ownMessage = props.ownMessage === true;
    const classes = ownMessage ? ownMessageClasses : otherMessageClasses;
    const monogram = getMonogram(props.name);
    const timestamp = new Date(props.timestamp);
    const isoDate = timestamp.toISOString().split('T')[0];
    const timestampText = timestamp.toLocaleString();

    return (
        <article className={classes}>
            <footer className="flex justify-between items-center mb-2">
                <div className="flex items-center">
                    <div className="mr-2 inline-flex overflow-hidden relative justify-center items-center w-10 h-10 bg-gray-100 rounded-full dark:bg-gray-600">
                        <span className="font-medium text-gray-600 dark:text-gray-300">{monogram}</span>
                    </div>
                    <div>
                        <span className="text-sm font-semibold text-gray-900 dark:text-white">{props.name}</span>
                        <p className="text-sm font-light text-gray-500 dark:text-gray-400"><time pubdate="pubdate" dateTime={isoDate} title={timestampText}>{timestampText}</time></p>
                    </div>
                </div>
            </footer>
            <p className="text-gray-500 dark:text-gray-400">{props.message}</p>
        </article>
    );
};

export default Message;
