import axios from "axios";

const client = axios.create({
    baseURL: "/api/v1/chat"
    //baseURL: "https://nbqnxdp4j1.execute-api.eu-central-1.amazonaws.com/dev/messages"
});

export function getMessages(responseDataHandler, errorHandler = (error) => console.log(console.error.toJSON())) {
    client.get('/messages').then((response) => responseDataHandler(response.data)).catch(errorHandler);
}

export function postMessage(name, message, responseDataHandler = (responseData) => console.log(responseData), errorHandler = (error) => console.log(console.error.toJSON())) {
    client.post('/messages', { name: name, message: message }).then((response) => responseDataHandler(response.data)).catch(errorHandler);
}