import { useState, useEffect } from "react";
import Message from "./Message";
import { getMessages, postMessage } from "./services/ChatService";

const NAME_SESSION_STORAGE_KEY = "chatUserName";

function Chat() {
    const [messages, setMessages] = useState([]);
    const [name, setName] = useState(sessionStorage.getItem(NAME_SESSION_STORAGE_KEY));

    const onNameInput = (event) => {
        const name = event.target.value;
        setName(name);
        sessionStorage.setItem(NAME_SESSION_STORAGE_KEY, name);
    };

    const onSend = (e) => {
        const messageTextArea = document.getElementById("message");
        const message = messageTextArea.value;

        postMessage(name, message, () => messageTextArea.value = "");
    }

    const fetchMessages = () => getMessages((responseData) => setMessages(responseData.messages));

    useEffect(() => {
        fetchMessages();
        const interval = setInterval(fetchMessages, 1000);
        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <section className="bg-white dark:bg-gray-900 py-8 lg:py-16">
            <div className="mx-auto max-w-screen-md px-4">
                <div className="flex justify-between items-center mb-6">
                    <h2 className="text-lg font-bold text-gray-900 lg:text-2xl dark:text-white">Send a message</h2>
                </div>

                <form action="#" className="grid gap-6 p-6 mx-auto mb-6 bg-white rounded-lg border border-gray-200 shadow-sm grid-cols sm:grid-cols-2 dark:bg-gray-800 dark:border-gray-700">
                    <div className="sm:col-span-2">
                        <label htmlFor="first-name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Your name</label>
                        <input type="text" id="first-name" value={name} onInput={onNameInput} className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="John Doe" required />
                    </div>
                    <div className="sm:col-span-2">
                        <label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">Your message</label>
                        <textarea id="message" rows="6" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="" required></textarea>
                    </div>
                    <button type="submit" onClick={onSend} className="py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-primary-700 sm:w-fit hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Send message</button>
                </form>

                {messages.map((message) => (
                    <Message timestamp={message.timestamp} name={message.name} message={message.message} key={message.timestamp} ownMessage={name === message.name} />
                ))}

            </div>
        </section>
    );
};

export default Chat;